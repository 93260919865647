import { FC } from 'react';
import { Z_INDEX } from 'constants/common';
import classNames from 'classnames';

interface Props {
  percentage: number;
  size?: 's' | 'l';
  position?: 'top' | 'bottom';
}

export const CurrentPercentageSavings: FC<Props> = ({
  percentage,
  size = 's',
  position = 'bottom',
}) => {
  const wrapperClass = classNames(`product-savings absolute ${Z_INDEX.level10}`, {
    'left-2': size === 's',
    'left-4': size === 'l',
    '-bottom-2': position === 'bottom',
    '-top-[1.6rem]': position === 'top',
  });

  const rotatedClass = classNames(
    'origin-center -rotate-[2deg] bg-[#FCE91F] text-center content-center',
    {
      'h-8 w-16': size === 's',
      'h-10 w-24': size === 'l',
    }
  );

  const textClass = classNames('text-black font-black origin-center rotate-[2deg]', {
    'text-[1.05rem]': size === 's',
    'text-[1.4rem]': size === 'l',
  });

  return (
    <div className={wrapperClass}>
      <div className={rotatedClass}>
        <p translate="no" className={textClass}>
          -{percentage}%
        </p>
      </div>
    </div>
  );
};
