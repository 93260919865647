import { FC } from 'react';
import { OriginalPercentageSavings } from 'components/product/OriginalPercentageSavings';
import { CurrentPercentageSavings } from 'components/product/CurrentPercentageSavings';

interface Props {
  currentPercentage: number;
  originalPercentage: number | null;
  size?: 's' | 'l';
  position?: 'top' | 'bottom';
}

export const ProductSavings: FC<Props> = ({
  currentPercentage,
  originalPercentage,
  size = 's',
  position = 'bottom',
}) => {
  return (
    <>
      {originalPercentage && (
        <OriginalPercentageSavings
          percentage={originalPercentage}
          size={size}
          position={position}
        />
      )}
      <CurrentPercentageSavings percentage={currentPercentage} size={size} position={position} />
    </>
  );
};
