import { FC } from 'react';
import { Z_INDEX } from 'constants/common';
import classNames from 'classnames';

interface Props {
  percentage: number;
  size?: 's' | 'l';
  position?: 'top' | 'bottom';
}

export const OriginalPercentageSavings: FC<Props> = ({
  percentage,
  size = 's',
  position = 'bottom',
}) => {
  const wrapperClass = classNames(`product-savings absolute ${Z_INDEX.level20}`, {
    'left-2': size === 's',
    'left-4': size === 'l',
    'bottom-7': size === 's' && position === 'bottom',
    'bottom-10': size === 'l' && position === 'bottom',
    '-top-14': size === 's' && position === 'top',
    '-top-[4.25rem]': size === 'l' && position === 'top',
  });

  const rotatedClass = classNames(
    'origin-center -rotate-[2deg] bg-[#EAEAEA] text-center content-center',
    {
      'h-6 w-14': size === 's',
      'h-8 w-[3.75rem]': size === 'l',
    }
  );

  const textClass = classNames('text-[#58585A] font-semibold origin-center rotate-[2deg]', {
    'text-[1rem]': size === 's',
    'text-[1.1rem]': size === 'l',
  });

  return (
    <div className={wrapperClass}>
      <div className={rotatedClass}>
        <div className="relative">
          <div className="absolute left-0 right-0 top-[49%] -rotate-[15deg] border-t-2 border-[#8C8C8C]" />
          <p translate="no" className={textClass}>
            -{percentage}%
          </p>
        </div>
      </div>
    </div>
  );
};
